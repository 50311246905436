var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.mode === 'Whatsapp')?_c('div',{staticClass:"preview-window-container"},[_c('div',{staticClass:"preview-window-container__header_wa"},[_c('span',{staticClass:"header_wa__phone"},[_vm._v(_vm._s(_vm.phoneNumber))])]),_c('div',{staticClass:"preview-window-container__body_wa"},[(_vm.textMessage)?_c('div',{staticClass:"message-bubble_wa"},[(_vm.manualCommunication.activationMedia?.src)?_c('div',[(
                            _vm.manualCommunication.activationMedia?.thumbnail &&
                                _vm.manualCommunication.activationMedia?.originalName?.endsWith('.webm')
                        )?_c('div',{staticClass:"media-container_document media-container"},[_c('div',{staticClass:"preview-document-container"},[_c('v-icon',{staticStyle:{"color":"#798f9a"}},[_vm._v("mdi-file")]),_c('span',{staticClass:"preview-document-container__format"},[_vm._v("WEBM")])],1),_c('div',{staticClass:"file-description"},[_c('span',{staticClass:"file-description__title"},[_vm._v(_vm._s(_vm.manualCommunication.activationMedia?.originalName))]),_c('span',{staticClass:"file-description__size"},[_vm._v(" "+_vm._s(`${_vm.sizeFile} МБ • WEBM`))])])]):(
                            _vm.manualCommunication.activationMedia?.originalName?.endsWith('.mp4') &&
                                _vm.manualCommunication.activationMedia?.thumbnail
                        )?_c('div',{staticClass:"media-container"},[_c('img',{staticClass:"preview-img",attrs:{"src":_vm.manualCommunication.activationMedia?.thumbnail?.src}}),_c('span',{staticClass:"preview-icon"},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-play")])],1)]):(_vm.manualCommunication.activationMedia?.originalName?.endsWith('.pdf'))?_c('div',{staticClass:"media-container media-container_document",staticStyle:{"flex-direction":"column"}},[_c('div',{staticClass:"preview-pdf"}),_c('div',{staticClass:"file-description"},[_c('span',{staticClass:"file-description__title"},[_vm._v(_vm._s(_vm.manualCommunication.activationMedia?.originalName))]),_c('span',{staticClass:"file-description__size"},[_vm._v(" "+_vm._s(`${_vm.sizeFile} МБ • PDF`))])])]):_c('div',{staticClass:"media-container"},[_c('img',{staticClass:"preview-img",attrs:{"src":_vm.manualCommunication.activationMedia?.src}})])]):_vm._e(),_vm._l((_vm.processedValue),function(item){return [(item.type === 'bold')?_c('span',{staticClass:"message-bubble_wa_text mb-0",staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(/^(?=.*\*)[\* ]+$/.test(item.content) ? item.content : _vm.getVarValue(item.content.slice(1, -1))))]):(!item.type || item.type === 'text')?_c('span',{staticClass:"message-bubble_wa_text mb-0"},[_vm._v(_vm._s(item.val))]):_c('span',{staticClass:"message-bubble_wa_text mb-0"},[_vm._v(_vm._s(_vm.getVarValue(item.content)))])]}),_c('span',{staticClass:"view-status_wa"})],2):_vm._e()]),_c('div',{staticClass:"preview-window-container__footer_wa"})]):_c('div',{staticClass:"preview-window-container preview-window-container_blue"},[_c('div',{staticClass:"preview-window-container__header_tg"},[_c('span',{staticClass:"header_tg__phone"},[_vm._v(_vm._s(_vm.phoneNumber))])]),_c('div',{staticClass:"preview-window-container__body_tg"},[(_vm.textMessage)?_c('div',{staticClass:"message-bubble_tg"},[(_vm.manualCommunication.activationMedia?.src)?_c('div',[(
                            _vm.manualCommunication.activationMedia?.originalName?.endsWith('.pdf') ||
                                _vm.manualCommunication.activationMedia?.originalName?.endsWith('.webm')
                        )?_c('div',{staticClass:"media-container media-container_document_tg",staticStyle:{"flex-direction":"column"}},[_c('span',{staticClass:"download-file_tg"}),_c('div',{staticClass:"file-description file-description_tg"},[_c('span',{staticClass:"file-description__title file-description__title_tg"},[_vm._v(_vm._s(_vm.manualCommunication.activationMedia?.originalName))]),_c('span',{staticClass:"file-description__size file-description__size_tg"},[_vm._v(" "+_vm._s(`${_vm.sizeFile} МБ ${ _vm.manualCommunication.activationMedia?.originalName?.endsWith( '.pdf' ) ? 'PDF' : 'WEBM' }`))])])]):(
                            _vm.manualCommunication.activationMedia?.originalName?.endsWith('.mp4') &&
                                _vm.manualCommunication.activationMedia?.thumbnail
                        )?_c('div',{staticClass:"media-container media-container_tg"},[_c('img',{staticClass:"preview-img preview-img_tg",attrs:{"src":_vm.manualCommunication.activationMedia?.thumbnail?.src}}),_c('span',{staticClass:"preview-icon preview-icon_tg"},[_c('span',{staticStyle:{"color":"white","font-size":"6px"}},[_vm._v("0:00")]),_c('v-icon',{staticStyle:{"color":"white","font-size":"6px","margin-left":"2px"}},[_vm._v("mdi-volume-mute")])],1)]):_c('div',{staticClass:"media-container media-container_tg preview-img_tg"},[_c('img',{staticClass:"preview-img",attrs:{"src":_vm.manualCommunication.activationMedia?.src}})])]):_vm._e(),_vm._l((_vm.processedValue),function(item){return [(item.type === 'bold')?_c('span',{staticClass:"message-bubble_tg_text mb-0",staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(_vm.getVarValue(item.content.slice(2, -2))))]):(!item.type || item.type === 'text')?_c('span',{staticClass:"message-bubble_tg_text mb-0"},[_vm._v(_vm._s(item.val))]):_c('span',{staticClass:"message-bubble_tg_text mb-0"},[_vm._v(_vm._s(_vm.getVarValue(item.content)))])]}),_c('span',{staticClass:"view-status_tg"})],2):_vm._e()]),_c('div',{staticClass:"preview-window-container__footer_tg"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }

    import Vue from 'vue'
    import { useProject } from '@/hooks/useProject'

    export default Vue.extend({
        data: () => ({
            showTooltip: false,
        }),

        methods: {
            handleClick() {
                this.$router.push(`/project/${this.projectId}/billing?section=tariffs&default=tariff2`)
            }
        },
        setup(props, {}) {
            const { project } = useProject()
            const  projectId  = project.value.id
            return {
                projectId
            }
        }
    })

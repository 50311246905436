
import * as _ from 'lodash'
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, PropType, ref, watch } from 'vue'
import { Connector } from '@/types/main'
import NameBadge from './NameBadge.vue'
import { MESSAGE_VARS } from '../../../vars/GCB2'
import messageVarsService from '../../../helpers/gcb2/messageVars'
import { ManualCommunication } from '../../../types/GCB2'
import messages from '@dataheroes/messages'
import { useProject } from '../../../hooks/useProject'
import { useUser } from '../../../hooks/useUser'
import api from '@root/src/api/api'
import CloseIcon from '@/assets/vuetifyIcons/close.vue'
import SpinArrowsIcon from '@/assets/vuetifyIcons/spinArrows.vue'
import FrameIcon from '@/assets/vuetifyIcons/frame.vue'
import StapleIcon from '@/assets/vuetifyIcons/staple.vue'
import CopyIcon from '@/assets/vuetifyIcons/copy.vue'
import ResetArrowIcon from '@/assets/vuetifyIcons/resetArrow.vue'
import SpinnerLoader from '../../SpinnerLoader.vue'
type EditorState = 'view' | 'edit'

const varsIds = messages.vars.vars.map(el => el.aliases.map(alias => `{${alias}}`)).flat()
export default defineComponent({
    components: {
        NameBadge,
        CloseIcon,
        SpinArrowsIcon,
        SpinnerLoader,
        FrameIcon,
        StapleIcon,
        CopyIcon,
        ResetArrowIcon,
    },
    props: {
        label: { type: String },
        value: { type: String, required: true },
        readonly: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Введите текст...' },
        fontSize: { type: Number, default: 12 },
        gcb2Connector: { type: Object as PropType<Connector>, default: undefined },
        manualCommunication: { type: Object as PropType<ManualCommunication>, default: undefined },
        withMedia: { type: Boolean, default: false },
        allMediaLoading: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const key = ref(1)
        const root = getCurrentInstance()!.proxy
        const { project } = useProject()
        const { user } = useUser()
        const editorState = ref<EditorState>('view')
        const tooltip = ref(false)
        const editorViewerHeight = ref(0)
        const autoGrow = ref(false)
        const varsRegexp = computed(() => new RegExp(varsIds.join('|'), 'g'))
        const allowedMimeTypes = ref(['image/png', 'image/jpeg', 'application/pdf', 'video/mp4'])
        const fileLoading = ref(false)
        const isSrcExist = computed(() => {
            return props.manualCommunication.activationMedia?.src
        })
        const maxFileSize = ref(20 * 1024 * 1024)
        const disabledStapleButton = computed(() => {
            if (
                props.manualCommunication.activationMedia ||
                fileLoading.value ||
                props.allMediaLoading
            ) {
                return true
            } else {
                return false
            }
        })
        const disabledResetButton = computed(() => {
            if (
                (textBeforeChange.value === props.manualCommunication.textMessage &&
                    mediaBeforeChange.value?.src === props.manualCommunication.activationMedia?.src) ||
                fileLoading.value ||
                props.allMediaLoading
            ) {
                return true
            }
        })
        const textBeforeChange = ref(null)
        const mediaBeforeChange = ref(null)
        const processedValue = computed((): any[] => {
            let result: any[] = []
            const maybeVars: any[] = []
            const splitted: string[] = []
            let curStrIndex = 0

            const matches = props.value ? props.value.matchAll(varsRegexp.value) : []
            const matchesArr = [...matches]

            if (matchesArr.length === 0) {
                result = [{ id: 1, val: props.value }]
                return result
            }
            for (let match of matchesArr) {
                maybeVars.push({
                    val: match[0],
                    startIndex: match.index,
                    endIndex: Number(match.index) + match[0].length,
                })
            }
            maybeVars.forEach((el, i) => {
                splitted.push(props.value.substring(curStrIndex, el.startIndex))
                splitted.push(el.val)
                if (i === maybeVars.length - 1) {
                    splitted.push(props.value.substring(el.endIndex, props.value.length))
                }
                curStrIndex = el.endIndex
            })
            result = splitted.map((el, i) =>
                varsIds.includes(el) ? { id: i, varId: el } : { id: i, val: el }
            )
            return result
        })
        const copy = () => {
            navigator.clipboard.writeText(
                messageVarsService.resolveVars(props.value, props.gcb2Connector, props.manualCommunication)
            )
            setTimeout(() => {
                tooltip.value = false
            }, 1000)
        }
        const setEditorState = (state: EditorState) => {
            if (props.readonly) return
            if (state === 'edit') {
                setTimeout(() => {
                    ;(root.$refs.textarea as HTMLInputElement).focus()
                    autoGrow.value = true
                }, 0)
            } else {
                autoGrow.value = false
            }
            editorState.value = state
        }
        watch(
            () => props.value,
            () => {
                if (editorState.value === 'view') {
                    setTimeout(() => {
                        const editorContentViewer: HTMLElement = root.$refs.editorContentViewer as HTMLElement
                        if (editorContentViewer) {
                            editorViewerHeight.value = parseFloat(String(editorContentViewer.offsetHeight))
                            ;(root.$refs.textarea as any).$refs.input.style.height =
                                editorViewerHeight.value + 'px'
                        }
                    }, 0)
                }
            },
            { immediate: true }
        )
        const getVarValue = (varId: string) => {
            const text = messages.substituteVars({
                text: varId,
                data: {
                    communication: props.manualCommunication,
                    gcb2Connector: props.gcb2Connector,
                    project: project.value,
                    userId: user.value.sub,
                },
            }).text
            console.log({
                comm: props.manualCommunication,
                gcb2: props.gcb2Connector,
                project: project.value,
                user: user.value.sub,
            })
            return text
        }
        const showImagePreview = computed(() => {
            const imageExtensions = ['.png', '.jpg', '.gif', '.jpeg']
            return (
                props.manualCommunication.activationMedia?.src &&
                imageExtensions.some(ext => props.manualCommunication.activationMedia?.src.endsWith(ext))
            )
        })
        const showVideoPreview = computed(() => {
            const videoExtensions = ['.mp4']
            return (
                props.manualCommunication.activationMedia?.src &&
                videoExtensions.some(ext => props.manualCommunication.activationMedia?.src.endsWith(ext))
            )
        })
        const showDocPreview = computed(() => {
            const docExtensions = ['.pdf']
            return (
                props.manualCommunication.activationMedia?.src &&
                docExtensions.some(ext => props.manualCommunication.activationMedia?.src.endsWith(ext))
            )
        })
        const uploadFile = async (files: File[] | FileList | null) => {
            if (!files) return
            emit('set-loading', true)
            fileLoading.value = true
            const file = _.first<File>(files)
            if (!allowedMimeTypes.value.includes(file?.type!)) {
                root.$store.dispatch('callNotify', 'Не верный формат файла')
                emit('set-loading', false)
                fileLoading.value = false
                return
            }

            if (file && file?.size > maxFileSize.value) {
                root.$store.dispatch('callNotify', 'Превышен максимальный размер файла')
                clearFile()
                emit('set-loading', false)
                fileLoading.value = false
                return
            }
            const { data, error } = await api.file.uploadMedia({ projectId: project.value.id, file: file! })
            if (error) {
                root.$store.dispatch('callNotify', 'Произошла ошибка при загрузке файла')
                emit('set-loading', false)
                fileLoading.value = false
                return
            }
            update(data!.url)
            if (file?.type.startsWith('video/')) {
                await generateVideoThumbnail(file!)
            }
            emit('set-loading', false)
            fileLoading.value = false
        }
        const update = (v: any | null) => {
            props.manualCommunication.activationMedia = v
        }
        const generateVideoThumbnail = (file: File) => {
            return new Promise(resolve => {
                const video = document.createElement('video')
                const canvas = document.createElement('canvas')
                const url = URL.createObjectURL(file)

                video.src = url
                video.addEventListener('loadeddata', async () => {
                    video.currentTime = 1 // Берем кадр на 1-й секунде
                })

                video.addEventListener('seeked', () => {
                    canvas.width = video.videoWidth
                    canvas.height = video.videoHeight
                    const ctx = canvas.getContext('2d')!
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

                    canvas.toBlob(async blob => {
                        const thumbFile = new File([blob!], 'thumbnail.png', { type: 'image/png' })
                        const { data } = await api.file.uploadMedia({
                            projectId: project.value.id,
                            file: thumbFile,
                        })

                        update({
                            ...(props.manualCommunication?.activationMedia || {}),
                            thumbnail: data!.url,
                        })

                        URL.revokeObjectURL(url)
                        resolve(true)
                    }, 'image/png')
                })
            })
        }
        const clearFile = () => {
            update(null)
            key.value++
        }
        const onInputChange = (e: Event) => {
            const target = e.target as HTMLInputElement
            const files = target.files!
            uploadFile(files)
        }
        const openUploadWindow = () => {
            const fileInput = root.$refs['fileInput'] as HTMLInputElement
            fileInput.click()
        }
        const onClickOutside = () => {
            setEditorState('view')
        }
        const resetChange = () => {
            props.manualCommunication.activationMedia = mediaBeforeChange.value
            props.manualCommunication.textMessage = textBeforeChange.value
        }
        const setActiveItem = () => {
            emit('set-item', props.manualCommunication)
        }
        onMounted(() => {
            mediaBeforeChange.value = props.manualCommunication.activationMedia
            textBeforeChange.value = props.manualCommunication.textMessage
        })
        return {
            props,
            emit,
            copy,
            autoGrow,
            tooltip,
            editorState,
            setEditorState,
            processedValue,
            editorViewerHeight,
            messages,
            getVarValue,
            showImagePreview,
            showVideoPreview,
            showDocPreview,
            allowedMimeTypes,
            clearFile,
            onInputChange,
            openUploadWindow,
            fileLoading,
            onClickOutside,
            disabledStapleButton,
            disabledResetButton,
            resetChange,
            setActiveItem,
            key
        }
    },
})

import cfg from '../../config'
import { getInstance } from '../auth'
import { ApiWrapper } from '../types/main'
import { getError } from '../utils'
import messages from '@dataheroes/messages'

// Для того тайпскрпт придумали?
type MessagesDH = ReturnType<() => ReturnType<typeof messages.getMessages>>
type MessageDH = MessagesDH[0]
type InnerMessageDH = MessageDH['messages'][0]
export type InnerMessageDHWithActivationId = InnerMessageDH & { activationId : number } & {media?: {src: string, mimeType: string}}
export type ModifiedMessageDH = Omit<MessageDH, 'messages'> & {
    messages : InnerMessageDHWithActivationId[]
}

export const authApi = {}
export interface massMailmessage {
    to: string
    from: string | number
    text: string
    communicationId: string | null
    activationId? : string
    media?: {
        src: string
        mimeType: string
    }
}
interface sendMassMailParams {
    projectId: string
    connectorId: string
    service: 'whatsapp' | 'yClients' | 'whatsappBasis'
    messages: massMailmessage[],
    sendAfterCommUpdate?: boolean
}

export async function sendMassMail({
    projectId,
    connectorId,
    service,
    messages,
    sendAfterCommUpdate
}: sendMassMailParams): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/massender/send`)
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            connectorId,
            service,
            messages,
            sendAfterCommUpdate
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}

/* {
    "projectId": "AHUVGLCZO",
    "connectorId": "AHUVGLCZO_whatsapp_hhd",
    "service": "whatsapp",
    "messages": [
        {
            "to": "79192156482",
            "from": "79192156482",
            "text": "привет Уважаемый клиент\n\n Выше отступ",
            "communicationId": "328484811"
        }
    ]
} */

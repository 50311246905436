
import Vue, { PropType } from 'vue'
import { ManualCommunication } from '../../../../types/GCB2'
import _, { toNumber } from 'lodash'
import messages from '@dataheroes/messages'
import { Connector } from '@/types/main'
import { useUser } from '@root/src/hooks/useUser'
import { useProject } from '@root/src/hooks/useProject'

const varsIds = messages.vars.vars.map(el => el.aliases.map(alias => `{${alias}}`)).flat()
export default Vue.extend({
    components: {},
    props: {
        mode: { type: String as PropType<string> },
        phoneNumber: { type: String as PropType<string> },
        textMessage: { type: String as PropType<string> },
        gcb2Connector: { type: Object as PropType<Connector>, default: undefined },
        manualCommunication: { type: Object as PropType<ManualCommunication>, default: undefined },
    },
    data: () => ({}),
    computed: {
        project() {
            const { project } = useProject()
            return project
        },
        user() {
            const { user } = useUser()
            return user
        },
        varsRegexp() {
            return new RegExp(varsIds.join('|'), 'g')
        },
        processedValue() {
            let result: any[] = []
            const elements: any[] = []
            const splitted: any[] = []
            let curStrIndex = 0

            // Новое регулярное выражение для поиска {var} и *текст*
            const combinedRegex = /(\{.*?\}|\*.*?\*)/g
            const combinedRegexTg = /(\{.*?\}|\*\*.*?\*\*)/g

            const matches = this.textMessage
                ? this.textMessage.matchAll(this.mode === 'Whatsapp' ? combinedRegex : combinedRegexTg)
                : []
            const matchesArr = [...matches]

            if (matchesArr.length === 0) {
                return [{ id: 1, val: this.textMessage }]
            }

            // Собираем все элементы (переменные и жирный текст)
            for (const match of matchesArr) {
                elements.push({
                    val: match[0],
                    startIndex: match.index,
                    endIndex: match.index + match[0].length,
                    type: match[0].startsWith('{') ? 'var' : 'bold', // Определяем тип элемента
                })
            }

            // Разбиваем исходный текст на части
            elements.forEach((el, i) => {
                // Добавляем текст перед элементом
                splitted.push(this.textMessage.substring(curStrIndex, el.startIndex))

                // Добавляем сам элемент с типом
                splitted.push({
                    type: el.type,
                    content: el.val, // Убираем обрамляющие символы
                })

                // Добавляем оставшийся текст после последнего элемента
                if (i === elements.length - 1) {
                    splitted.push(this.textMessage.substring(el.endIndex))
                }

                curStrIndex = el.endIndex
            })

            // Преобразуем в финальный формат
            result = splitted
                .filter(el => el !== '') // Убираем пустые строки
                .map((el, i) => {
                    if (typeof el === 'object') {
                        return {
                            id: i,
                            type: el.type,
                            content: el.content,
                        }
                    }
                    return {
                        id: i,
                        type: 'text',
                        val: el,
                    }
                })

            return result
        },
        sizeFile() {
            return (toNumber(this.manualCommunication.activationMedia?.size) / 1024).toFixed(2)
        },
    },
    watch: {},
    methods: {
        getVarValue(varId: string) {
            const text = messages.substituteVars({
                text: varId,
                data: {
                    communication: this.manualCommunication,
                    gcb2Connector: this.gcb2Connector,
                    project: this.project.value,
                    userId: this.user.value.sub,
                },
            }).text
            return text
        },
    },
})
